import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import apiServices from "services/RequestHandler"
import { dateFormat, timeFormatWithSec, utc_time_zone } from "./constants"

export interface PromoCodeState {
  all_promo_code: any
  all_promo_code_data: any
  create_promo_code: any
  update_promo_code: any
  get_single_promo_code_data: any
  activity: boolean
}

interface APIParams {
  end_point: string
  body: any
}

const initialState: PromoCodeState = {
  all_promo_code: [],
  all_promo_code_data: [],
  create_promo_code: {},
  update_promo_code: {},
  get_single_promo_code_data: {},
  activity: false,
}

export const get_all_promo_Cde = createAsyncThunk(
  "all/promo-code",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "promo-code")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const get_single_promo_code = createAsyncThunk(
  "promo-code/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "promo-code")
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const create_promo_code = createAsyncThunk(
  "create/promo-code",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "promo-code"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const update_promo_code = createAsyncThunk(
  "update/prpmo-code",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "promo-code"
      )
      return response
    } catch (err) {
      console.log(err)
    }
  }
)

export const promo_code_slicer = createSlice({
  name: "promoCode",
  initialState,
  reducers: {
    handle_clear_form: (state: PromoCodeState, action: any) => {
      state.update_promo_code = {}
      state.create_promo_code = {}
      state.get_single_promo_code_data = {}
      state.activity = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_all_promo_Cde.pending, (state: any) => {
        state.activity = true
      })

      .addCase(get_all_promo_Cde.fulfilled, (state: any, { payload }: any) => {
        try {
          let promo = [] as any

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index]
            promo.push([
              { name: item?.id || "--", hidden: true },
              {
                name: item?.promo_code,
              },
              {
                name:
                  item?.promo_type === "entire_order"
                    ? "Entire Order"
                    : item?.promo_type === "product_specific"
                      ? "Product Specific"
                      : "",
              },
              {
                name:
                  item?.discount_type === "percent_off"
                    ? "Percent Off"
                    : item?.discount_type === "dollar_off"
                      ? "Dollar Off"
                      : item?.discount_type === "fixed_price"
                        ? "Fixed Price"
                        : "",
              },
              {
                name: `${Number(item?.discount ?? 0)} ${
                  item?.discount_type === "percent_off"
                    ? " %"
                    : item?.discount_type === "dollar_off"
                      ? " ₾"
                      : item?.discount_type === "fixed_price"
                        ? " ₾"
                        : " ₾"
                }`,
                alignment: "center",
              },
              {
                name: item?.start_date
                  ? moment(item?.start_date).format(dateFormat)
                  : "",
              },
              {
                name: item?.end_date
                  ? moment(item?.end_date).format(dateFormat)
                  : "",
              },
              { name: item?.is_active ? "Active" : "Inactive" },
              {
                name: item?.customer_numbers?.length ?? 0,
                alignment: "center",
              },
              {
                name: moment(item?.created_at)
                  .utcOffset(utc_time_zone)
                  .format(`${dateFormat} ${timeFormatWithSec}`),
              },
              {
                name: item?.added_by || "--",
              },
              {
                name:
                  item?.updated_at !== item?.created_at
                    ? moment(item?.updated_at)
                        .utcOffset(utc_time_zone)
                        .format(`${dateFormat} ${timeFormatWithSec}`)
                    : "--",
              },
              {
                name: item?.modified_by || "--",
              },
            ])
          }

          state.all_promo_code = promo
          state.all_promo_code_data = payload?.data
          state.activity = false
        } catch (err) {
          console.log(err)
        }
      })

      .addCase(
        get_single_promo_code.pending,
        (state: any, { payload }: any) => {
          state.activity = true
        }
      )

      .addCase(
        get_single_promo_code.fulfilled,
        (state: any, { payload }: any) => {
          try {
            state.get_single_promo_code_data = payload.data as any
            state.activity = false
          } catch (error) {
            console.log(error)
          }
        }
      )

      .addCase(create_promo_code.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(create_promo_code.fulfilled, (state: any, { payload }: any) => {
        try {
          state.create_promo_code = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })

      .addCase(update_promo_code.pending, (state: any, { payload }: any) => {
        state.activity = true
      })

      .addCase(update_promo_code.fulfilled, (state: any, { payload }: any) => {
        try {
          state.update_promo_code = payload
          state.activity = false
        } catch (error) {
          state.activity = false
        }
      })
  },
})

export const { handle_clear_form } = promo_code_slicer.actions

export default promo_code_slicer.reducer
