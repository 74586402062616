import moment from 'moment'
import _ from 'lodash'
import { getDataFromLocalStorage } from './localStore.utils'

export const _secretKey = 'd0m!no$-P!zz@'
export const _secretData = '@zerBa!j@n'

export const current_zone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const number_format_without_currency = (value: number) =>
    Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value)

const num_to_word = {
    0: 'Zero',
    1: 'One',
    2: 'Two',
    3: 'Three',
    4: 'Four',
    5: 'Five',
    6: 'Six',
    7: 'Seven',
    8: 'Eight',
    9: 'Nine',
    10: 'Ten',
    11: 'Eleven',
    12: 'Twelve',
    13: 'Thirteen',
    14: 'Fourteen',
    15: 'Fifteen',
    16: 'Sixteen',
    17: 'Seventeen',
    18: 'Eighteen',
    19: 'Nineteen',
    20: 'Twenty',
    30: 'Thirty',
    40: 'Forty',
    50: 'Fifty',
    60: 'Sixty',
    70: 'Seventy',
    80: 'Eighty',
    90: 'Ninety',
} as any

const placement = {
    100: ' Hundred',
    1000: ' Thousand',
    1000000: ' Million',
    1000000000000: ' Trillion',
}

export const convert_num_to_word = (num: any): any => {
    const limiter = (val: any) => (num < val) as any
    const limiterIndex = Object.keys(placement).findIndex(limiter) as any
    const limiterKey = Object.keys(placement)[limiterIndex] as any
    const limiterVal = Object.values(placement)[limiterIndex - 1] as any
    const limiterMod = Object.keys(placement)[limiterIndex - 1] as any

    if (num_to_word[num]) {
        return num_to_word[num]
    }

    if (num < 100) {
        const whole = Math.floor(num / 10) * 10
        const part = num % 10
        return num_to_word[whole] + ' ' + num_to_word[part]
    }

    if (num < limiterKey) {
        const whole = Math.floor(num / limiterMod)
        const part = num % limiterMod
        if (part === 0) {
            return convert_num_to_word(whole) + limiterVal
        }
        return (
            convert_num_to_word(whole) +
            limiterVal +
            ' and ' +
            convert_num_to_word(part)
        )
    }
}

export const capitalize_string = (word: string): string => {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)
}

export const time_ago = (date: any) => {
    var seconds = Math.floor((Number(new Date()) - Number(date)) / 1000)

    var interval = seconds / 31536000

    if (interval > 1) {
        return Math.floor(interval) + ' years ago'
    }

    interval = seconds / 2592000
    if (interval > 1) {
        return Math.floor(interval) + ' months ago'
    }

    interval = seconds / 86400
    if (interval > 1) {
        return Math.floor(interval) + ' days ago'
    }

    interval = seconds / 3600
    if (interval > 1) {
        return Math.floor(interval) + ' hours ago'
    }

    interval = seconds / 60

    if (interval > 1) {
        return Math.floor(interval) + ' minutes ago'
    }

    return Math.floor(seconds) + ' seconds ago'
}

export const can_perform_action = (module_name: any) => {
    try {
        if (!module_name) {
            return false
        }

        const user = getDataFromLocalStorage('user')
        const permissions = user?.permissions
        let perm = permissions?.filter(
            (i: any) => i?.module_name === module_name
        )[0]

        if (
            perm?.read ||
            perm?.write ||
            perm?.update ||
            perm?.delete ||
            perm?.print ||
            perm?.download
        ) {
            return true
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}

export const set_localize_content = (obj: any, language: string) => {
    return obj[language]
}

export const remove_null_keys = (obj: any) => {
    Object.keys(obj)?.forEach((key) => {
        if (obj[key] === null || obj[key] === '') {
            delete obj[key]
        }
    })
}

export const is_time_in_range = (time: any, range: any) => {
    try {
        const startTime = moment(`${time?.ts_start}`, 'HH:mm')
        const endTime = moment(`${time?.ts_end}`, 'HH:mm')
        const startSlot = moment(range?.start_slot, 'HH:mm')
        const endSlot = moment(range?.end_slot, 'HH:mm')

        if (
            (startSlot.isBefore(startTime) && endSlot.isAfter(startTime)) ||
            (startSlot.isBetween(startTime, endTime, null, '[]') &&
                endSlot.isBetween(startTime, endTime, null, '[]'))
        ) {
            console.log('startTime', startTime, endTime, startSlot, endSlot)
        } else if (
            startSlot.isBefore(startTime) !== endSlot.isAfter(startTime)
        ) {
        }

        return (
            (startSlot.isBefore(startTime) && endSlot.isAfter(startTime)) ||
            (startSlot.isBetween(startTime, endTime, null, '[]') &&
                endSlot.isBetween(startTime, endTime, null, '[]'))
        )
    } catch (error) {
        console.log(error)
    }
}

export const handleSentenceCase = (str: string) => {
    const words = str?.split(' ')
    const capitalizedWords = words?.map((word) => _.capitalize(word))
    const updatedStr = capitalizedWords?.join(' ')
    return updatedStr
}

export const structuredDataForOrderItmes = (_orders: any) => {
    try {
        let orders: any = JSON.stringify(_orders)
        orders = JSON.parse(orders ?? '[]') ?? []
        if (orders?.items?.length < 1) {
            return
        }
        let final_items = []
        for (let index = 0; index < orders?.items?.length; index++) {
            let order_item = orders?.items?.[index] as any
            order_item.is_deleted = false

            if (order_item?.sub_category_code === 'half_half') {
                if (order_item?.deal_discount) {
                    const checkDeal = orders?.items.findIndex(
                        (i: any) => i?.code === order_item?.deal_discount_code
                    )
                    const deal_item = orders?.items[checkDeal]
                    deal_item?.products?.push(order_item)
                    order_item.show_individual = false
                    order_item.is_deleted = true
                } else {
                    order_item.show_individual = true
                }
                final_items.push({
                    ...order_item,
                    type: 'half_half',
                })
            } else if (order_item?.category_code === 'offers') {
                final_items.push({ ...order_item, type: 'offers' })
            } else if (order_item?.category_code === 'pizza') {
                final_items.push({ ...order_item, type: 'pizza' })
            } else {
                final_items.push({ ...order_item, type: 'others' })
            }
        }
        return final_items?.filter((i: any) => i?.is_deleted === false)
    } catch (error) {
        console.log(error)
    }
}
